.Toastify__toast {
  background-color: #8fabe2 !important;
  color: #fff !important;
}

.Toastify__toast::before {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 8px !important;
  border-radius: 3px !important;
  content: "" !important;
}
.Toastify__toast--success::before {
  background-color: #05eb02 !important;
}

.Toastify__toast--error::before {
  background-color: #d90d10 !important;
}
.Toastify__close-button {
  color: #fff !important;
}
