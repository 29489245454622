@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@font-face {
  font-family: "SF Pro Display";
  src: local("SF-Pro-Display-Regular"),
    url("../assets/Font/SF-Pro-Display-Medium.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF-Pro-Display-Regular"),
    url("../assets/Font/SF-Pro-Display-Semibold.otf") format("truetype");
  font-weight: 600;
}
html {
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
}
body {
  background-color: #f8fbff;
}

.typography {
  @apply font-sans leading-[21px] font-semibold tracking-[-0.32px];
}

.phone-input input {
  outline: none;
}

.day-input {
  font-size: 32px;
}
.safari {
  height: calc(100vh - 140px);
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 467px) {
  body {
    overflow-y: hidden;
  }
  .day-input {
    font-size: 18px !important;
  }
  .safari .actions {
    bottom: 5%;
  }
}
